import './Components/Styles/style.scss';
import Login from './Components/Login';
import Register from './Components/Register';
import Home from './Components/Home';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './context/AuthContext';

function App() {

  const {currentUser} = useContext(AuthContext)
  console.log(currentUser)


  const ProtectedRoute = ({children}) => {
    if(!currentUser){
      return <Navigate to="/login"/>
    }

    return children

  }


  return (
  <BrowserRouter>
  <Routes>
    <Route path="/"> 
      <Route index element={<ProtectedRoute>
        <Home/>
        </ProtectedRoute>} />
      <Route path="login" element= {<Login/>} />
      <Route path="register" element= {<Register/>} />
      </Route>
  </Routes>
  </BrowserRouter>
  );
}

export default App;